import React from "react"
import { Waypoint } from 'react-waypoint';
import { Link } from 'gatsby'

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import ContactForm from "../components/contact/ContactForm"
import SEO from "../components/seo"

const Faq = () => {

	const openAccordion = (e, accordionId) => {
		e.preventDefault()
		console.log()
		if (document.getElementById(accordionId).classList.contains('active')) {
			document.getElementById(accordionId).classList.remove('active')
			e.target.classList.remove('active')
		} else {
			document.getElementById(accordionId).classList.add('active')
			e.target.classList.add('active')
		}				
	}  
	

  return (
    <div className="faq">
	  <Layout>
		<SEO 
			title="FAQ"
			description="Frequent Asked Web & Ecommerce Development Questions" 
		/>	  
          <section className="page-header">
			<Waypoint onEnter={() => handleWaypointIn('title-text', 'bounce-in-top')}>         
				<div id="title-text">                 
					<h1>FAQ</h1> 
				</div>    
			</Waypoint>   
          </section> 

		  <section className="content">
				<div className="white-bg">
					<div className="accordion-wrapper">
                    <Waypoint onEnter={() => handleWaypointIn('long-text')}>         
                        <div id="long-text">     					
						<h3 id="general">General</h3>

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-1')}>This is my first website - will you be able to help me as I'm new to this?</button>
							<div className="accordion-content" id="accordion-1">
							<p>Of course! I've worked on many websites in my time so will be happy to help guide you through the whole process.</p>
							<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
					
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-2')}>Which CMS would you recommend?</button>
							<div className="accordion-content" id="accordion-2">
								<p>At the moment, I recommend using WordPress for ease of use, customisability, amount of plugins to extend functionality and value for money. Another option would be to connect WordPress to Gatsby for a even quicker site.</p>
								<p>For more information please visit: <Link to="/web-development">Web Development</Link>.</p>

								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-3')}>Which ecommerce platform would you recommend?</button>
							<div className="accordion-content" id="accordion-3">
								<p>For the same reasons as which CMS I recommend I also recommend Woocomerce ecommerce plugin. For sites which require less customisation I would recommend Shopify.</p>
								<p>For more information please visit: <Link to="/ecommerce-web-development">Ecommerce Web Development</Link>.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-4')}>Can I have more than one language?</button>
							<div className="accordion-content" id="accordion-4">
								<p>Of course! I have worked on many multilingual websites. But there is an extra cost invloved with multilingual websites.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-4a')}>Do you charge by the hour, day or by project?</button>
							<div className="accordion-content" id="accordion-4a">
								<p>I prefer to charge on a project basis. This gives us both a fixed cost according to the agreed functionality. If later it is discovered you need more functionality then we can see if it can be included or whether an additional quote is required. However, other payment terms are possible.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
				
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-5')}>What are your payment terms?</button>
							<div className="accordion-content" id="accordion-5">
								<p><strong>For Web Development Only Packages:</strong></p>
								<ul>
									<li>50% down payment</li>
									<li>45% after completion of UAT (before migrating to live)</li>
									<li>5% before going live</li>
									
								</ul>
								<p><strong>For Web Development Packages with Design:</strong></p>
								
								<ul>
									<li>50% down payment</li>
									<li>15% after completion of UX design</li>
									<li>15% after completion of UI design</li>
									<li>15% after completion of UAT (before migrating to live)</li>
									<li>5% before going live</li>
								</ul>
								
								<p><strong>For both Web Development Only and Web Development with Design: </strong></p>
								
								<p>After final payment has been made we will put your site live and give you all source code / files.</p>	
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>	
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-6')}>How much does a website cost?</button>
							<div className="accordion-content" id="accordion-6">
								<p>It all depends on how much customisation is required and how big the site is. After I know what you want I will be able to give you a custom quote for your unique website.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-7')}>What sort of companies do you work with?</button>
							<div className="accordion-content" id="accordion-7">
								<p>I am happy to work with everybody from sole traders to multinational corporations. From blogs to corporate sites to ecommerce stores to custom software solutions. I’ve worked with B2Bs and B2Cs, with internal systems and external websites.</p>
								<p>For more information please visit: <Link to="/portfolio">Portfolio</Link>.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-8')}>How long does it take to build a website?</button>
							<div className="accordion-content" id="accordion-8">
								<p>It depends on several factors:</p>
								<p>– the complexity of the project<br/>– the size of the projects<br/>– the level of customisation required<br/>– how much
									interaction is needed with 3rd parties (I am dependent on the speed at which they respond)<br/>– how long it takes
									for your content to be ready<br/>– how long it takes you to sign-off designs or any feedback required<br/>– how long
									it takes to respond to any queries I might have&nbsp;<br/>– my current workload</p>
								<p>So a site can take anywhere from a few weeks to several months.</p>
								<p>If you select an open
									source CMS such as wordpress along with customisation it will be quicker than a complete Laravel or Ruby on Rails
									build. Connecting to external systems also can take extra time depending on the quality of their APIs and work
									required. When I give you a quote I will also give you a timeframe as well.</p>
									<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-9')}>What is your work process like?</button>
							<div className="accordion-content" id="accordion-9">
								<p>I will communicate with you to find out exactly what you want and how I can help.</p>
								<p><strong>For Web Development Only packages:</strong></p>
								<p>I will add a scope of work document listing all the functionalities required.
									After you have signed it I can start work.*</p>
								<p>Once the site has been completed I will put it on on a development server for you to test.</p>
								<p>After testing I require final payment and I will then add to your hosting server. If you do not have one I can help you set one up.</p>
								<p><strong>For Web Development with Design:</strong></p>
								<p>We (The designer/myself/project manager) will go into an even further discovery phase where we find out much more about your company and what you need, then
									give you a quote.</p>
								<p>Once you have your quote and agree to it we will sign a contract and I list all the functionality and scope of
									work required for that project*.</p>
								<p>One of my designers and (possibly) a project managers will then discuss the design with you and then come up with some concepts
									for you. Once the successful concept has been chosen I will require you to sign that design off.*</p>
								<p>I will then start building the site according to the signed-off design and scope of work.</p>
								<p>Once the site has been completed I will put it on our development server for you to test.</p>
								<p>For larger sites I will break the project into sprints and add to my development server at the end of each sprint so you can
									test.</p>
								<p>After testing I require final payment and will then either add to your chosen host.</p>
								<p>*These are important legally binding document that you will need to read and agree to. I will ensure all
									functionality listed in completed. Any functionality not listed will not be added unless a change request form is
									signed with possible payment required. For the signed-off design this is what the site will look like. Any change to
									the design will also need a signed change request form along with a possible payment.</p>	
									<p>For more information please visit: <Link to="/about-me">About Me</Link>.</p>	
									<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>		
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-10')}>Can you help me register a domain name?</button>
							<div className="accordion-content" id="accordion-10">
								<p>Yes I can help you register a domain name on your behalf at an additional cost. You can either buy a domain or if you already have one then we can use that domain.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-11')}>What form of communication do you use?</button>
							<div className="accordion-content" id="accordion-11">
								<p>You have unlimited email/whatsapp support throughout the project life cycle and after if you host with me or take out a maintenance packages.</p>

								<p>We can also meet via skype/zoom and for my Hong Kong clients I can offer face to face meetings when required.</p>	
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>			
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-12')}>Can you help me write content for my website?</button>
							<div className="accordion-content" id="accordion-12">
								<p>Not me personally but I know several copy writers who will be happy to assist you but there will be an additional cost. Please contact me for more information.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-13')}>Who adds the content to our site?</button>
							<div className="accordion-content" id="accordion-13">
								<p>For small sites I can help you add the content during the intial build. For large sites you are expected to add the content. If you require help I can offer a content placement package.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-14')}>Are third party plugins included in the quote?</button>
							<div className="accordion-content" id="accordion-14">
								<p>Any third party plugins that require additional payment are listed separately in the quote. If they are listed in the quote then they are free for the first year. After the first year there might be an additional cost - which will be noted.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-15')}>Do I own my own website?</button>
							<div className="accordion-content" id="accordion-15">
								<p>Yes! Once I have completed your website and final payment has been made I hand over all source code to you and it is yours to keep.</p>
								<p>You can either host with your chosen host or I can help you set a server up.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-16')}>How do I know how many people are visiting my site?</button>
							<div className="accordion-content" id="accordion-16">
								<p>I will connect your Google Analytics account (or another analytics company) tracking code. </p>

								<p>If you do not have a GA account I can help you set one up.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-17')}>Can I update my website myself after it's gone live?</button>
							<div className="accordion-content" id="accordion-17">
								<p>Yes you are free to update your website’s content after it has gone live.</p><p>If you need us to help you with any updates after going live I am available to do so at my hourly rate.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-18')}>Do you offer any guarantees on your web development?</button>
							<div className="accordion-content" id="accordion-18">
								<p>I would like to think that all my code is bug free but for more complex sites that’s very difficult to achieve. Therefore, I offer a 60 day guarantee after going live that any bugs with my code* will be fixed for free.</p><p>*Please note any bugs with third party code is not subject to this guarantee.</p>				
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-19')}>Are there any costs involved after completing a project?</button>
							<div className="accordion-content" id="accordion-19">
							<p>After completing a project the following costs will need to be paid yearly:</p>
							<ul>
								<li>Domain name</li>
								<li>Hosting </li>
								<li>Maintenance (this is optional – you are welcome to maintain the website yourself)</li>
								<li>3rd party plugins (some third party plugins used might need yearly subscriptions to be able to update them. I
									will list these and let you know the cost before I use them in your project. If you don’t want to use them I
									can look for alternatives or a custom solution)</li>
							</ul>			<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>	</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-20')}>What forms of payment do you accept?</button>
							<div className="accordion-content" id="accordion-20">
								<p>I accept bank transfer, cheque or credit card payment through Stripe.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-21')}>What's your refund policy?</button>
							<div className="accordion-content" id="accordion-21">
								<p>Refunds are judged on a case by case basis and the reason for the refund.&nbsp;</p>
								<p>It depends on the reason for needing a refund and how much work has already been undertaken. If agreed a partial refund might be possible.</p>			
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>	
							</div>
						</div>	

						</div>    
						</Waypoint>   
						<Waypoint onEnter={() => handleWaypointIn('design-text')}>         
                        <div id="design-text">     
						<h3>Design</h3>
						
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-23')}>Who needs to supply the images for my website?</button>
							<div className="accordion-content" id="accordion-23">
								<p>You are responsible for your own images but I can help you source stock photos if required and you choose Design as well.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-24')}>Can you help me with my branding and logo?</button>
							<div className="accordion-content" id="accordion-24">
								<p>Yes I know branding and logo specialists and can put you in touch with them.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-25')}>Will I see a mockup first?</button>
							<div className="accordion-content" id="accordion-25">
								<p>For web development with design packages we include 2-3 homepage concepts once a contract has been signed and 50% deposit paid. You can then choose one and make a few rounds of changes.&nbsp;</p><p>Once the homepage has been signed off I will develop the rest of the site making each page template, with you being able to make adjustments to each page template.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>				

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-26')}>Are all your sites responsive?</button>
							<div className="accordion-content" id="accordion-26">
								<p>Yes of course! Sites are all made to be responsive from mobiles up to desktops and in between. Although this assumes the design you provide me has mobile versions.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-36')}>Are you available full-time?</button>
							<div className="accordion-content" id="accordion-36">
								<p>Unfortunately not. I have a full-time job so am only available during evening and weekends.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>		

						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-37')}>Are you an agency?</button>
							<div className="accordion-content" id="accordion-37">
								<p>No I am not. Whist I work at an agency I have a full-time job so am only available during evening and weekends. If you want an agency please visit <a href="https://wecreate.com.hk" rel="noreferrer">WECREATE</a>.</p>
							</div>
						</div>								
						
						</div>    
						</Waypoint>   
						<Waypoint onEnter={() => handleWaypointIn('dev-text')}>         
                        <div id="dev-text">     

						<h3>Development</h3>
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-27')}>Are you able to integrate with our other systems?</button>
							<div className="accordion-content" id="accordion-27">
								<p>Yes I can! Provided your other systems has an API that I can use to communicate between the two systems then I should be able to connect them.</p>
								<p>For more information please visit: <Link to="/web-development">Web Development</Link>.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-28')}>Can you extend my current site that has been built by someone else?</button>
							<div className="accordion-content" id="accordion-28">
								<p>Yes it is possible if your site was made with WordPress, Woocommerce, Gatsby or Ruby on Rails. Contact me and let me know what you require and I can assess your current site and if I am able to help.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-29')}>Do you do Apps as well?</button>
							<div className="accordion-content" id="accordion-29">
								<p>I don't create Apps but know several app developers who would be more than happy to work with you. I can even include Apps in the quote and I will work with them on your behalf to get the App you require so you don’t need to.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-30')}>Can you make me a prototype before I sign a contract with you?</button>
							<div className="accordion-content" id="accordion-30">
								<p>Unfortunately not. A 50% deposit is required before any design or development work takes place.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-32')}>I had a bad experience with WordPress before - why do you recommend it?</button>
							<div className="accordion-content" id="accordion-32">
								<p>Not all wordpress/woocommerce sites are created equally. Like with anything, if made badly your wordpress site will be bad. If made well then you will have a great site.</p>
								<p>For more information please visit: <Link to="/web-development">Web Development</Link>.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-33')}>What is SEO and do you include any in your packages?</button>
							<div className="accordion-content" id="accordion-33">
								<p>SEO stands for Search Engine Optimisation. There are two main types of Search Engine Optimisation: paid-for and organic.</p><p>Paid-for SEO is paying search engines so your site appears at the top of the search results or in sponsored locations. I can help you with Paid-for SEO for an additional cost after your site has gone live.</p><p>Organic SEO refers to optimising your site naturally to produce a higher page rank and ultimately a higher listing in the results. I do this in all of my packages – including correct use of heading tags (H), page titles, meta descriptions, cross-site linking, etc.</p>	
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>			
							</div>
						</div>	
					
						
						<div className="accordion">
							<button className="accordion-heading" onClick={(e) => openAccordion(e, 'accordion-35')}>Can you help me point my DNS?</button>
							<div className="accordion-content" id="accordion-35">
								<p>Yes I can help you point your domain’s DNS to my server or your nominated hosting company.</p>
								<p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
							</div>
						</div>	
						</div>    
						</Waypoint>   						
					</div>
				</div>
					
				
        </section>

          <section className="contact-me" id="get-in-touch">
            <div className="all-wrapper">
                <h2>Get in touch</h2> 
                <ContactForm />       
            </div>          
        </section>                              
      </Layout> 
  </div>
  )
}

export default Faq